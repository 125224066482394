import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import COM_FileUpload from "../Component/COM_FileUpload";
import COM_ChatRoom_Lobby from "../Component/COM_ChatRoom_Lobby";

import COM_NavBar_Global from "../Component/COM_NavBar_Global";

//  -------------------------- mantine
import {
  RadioGroup,
  Radio,
  Autocomplete,
  TextInput,
  Button,
  Modal,
  Group,
  Checkbox,
  CheckboxGroup,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  Refresh,
  FileSearch,
  Check,
  ArrowBigRight,
  CircleCheck,
  MoodCry,
} from "tabler-icons-react";
import { NotificationsProvider } from "@mantine/notifications";
import { showNotification, updateNotification } from "@mantine/notifications";

//  -------------------------- Material Table
import MaterialTable, { Column } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import { UserContext } from "../UserContext";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X() {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);
  // ------------------------------------------------- DB

  // all ticket record - pending and solving
  const [DB_Users_all, setDB_Users] = useState([]);
  const get_DB_Users_all = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_eclipse_awesomelist__userinfo_all`)
      .then((res) => {
        setDB_Users(res.data);
        // console.log("# get_DB_Users_all", res.data);
      });
  };

  // all ticket record - pending and solving
  const set_DB_User__byuid = (
    _u_role,
    _u_outlets,
    _auth_by,
    _auth_by_pic,
    _u_uid
  ) => {
    axios
      .post(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_awesomelist__userinfo__auth_byuid`,
        {
          u_role: _u_role,
          u_outlets: _u_outlets,
          auth_by: _auth_by,
          auth_by_pic: _auth_by_pic,
          u_uid: _u_uid,
        }
      )
      .then((res) => {
        if (res.data?.[1]?.affectedRows > 0) {
          showNoti("Update Status : SUCCESSFUL", "true");
          get_DB_Users_all();
          setDialogOpened(false);
        } else {
          showNoti("Update Status : FAILED ! Please Contact Admin", "false");
        }
        // console.log("# set_DB_User__byuid", res.data);
      });
  };

  const cafesList = [
    "Gift BBA",
    "Gift BBK",
    "Gift Bukit Angsana",
    "Gift Cheras Perdana",
    "Gift Equine Park",
    "Gift Eve Suit",
    "Gift Impian",
    "Gift Kajang Indah",
    "Gift Kepong Baru",
    "Gift Kota Damansara",
    "Gift Lembah Maju",
    "Gift Manjalara",
    "Gift Nirwarna",
    "Gift OUG",
    "Gift Pandan Indah",
    "Gift Bukit Serdang",
    "Gift Sg Buloh",
    "Gift Sg Long",
    "Gift Sri Manja",
    "Gift Suntex",
    "Redsea PS",
    "Redsea PV12",
    "Redsea TBR",
    "Redsea WM",
  ];

  // ------------------------------------------------- Table Columns
  //u_name, u_email, u_contact, u_role, u_outlets, u_reg_dt, auth, auth_by, auth_dt, u_firebase_obj, archive, archive_by, archive_dt
  const columns = [
    {
      title: "User",
      field: `u_name`,
      width: "1px",
      render: (rowData) => (
        <>
          <img
            className="ico_profilPic"
            src={JSON.parse(rowData?.u_firebase_obj)?.photoURL}
          />
          <div>{`[${rowData?.id}] ${rowData?.u_name}`}</div>
        </>
      ),
    },

    {
      title: "Action",
      field: "u_role",
      width: "1px",
      render: (rowData) => (
        <>
          <Button
            fullWidth
            disabled={
              contextValue?.bd_UserInfo?.u_role == "admin" ? false : true
            }
            color="lime"
            onClick={() => {
              setDialogOpened(true);
              setSeleted_rowData(rowData);
              // console.log("rowData?.u_outlets", rowData?.u_outlets);
              setValue_CafesList(rowData?.u_outlets?.split(","));
            }}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      title: "Role",
      field: "u_role",
      width: "1px",
      render: (rowData) => (
        <>
          <div
            style={{
              color:
                rowData.u_role == "admin" || rowData.u_role == "staff"
                  ? "gold"
                  : "red",
            }}
          >
            {rowData.u_role}
          </div>
        </>
      ),
    },
    {
      title: "Outlets",
      field: "u_outlets",
      width: "50%",
    },
    {
      title: "Registered at",
      field: "u_reg_dt",
      width: "10%",
    },
    {
      title: "Auth by",
      field: "auth_by",
      width: "10%",
      render: (rowData) => (
        <>
          <img className="ico_profilPic" src={rowData?.auth_by_pic} />
          <div>{`${rowData?.auth_by}`}</div>
        </>
      ),
    },
    {
      title: "Auth At",
      field: "auth_dt",
      width: "10%",
    },
  ];

  // --------------------------------------- notification

  const showNoti = (_Title, _Status) => {
    showNotification({
      title: (
        <>
          <div className="flexRow">{_Title}</div>
        </>
      ),
      message: ``,
      color: _Status == "true" ? "green" : "red",
      icon: <> {_Status == "true" ? <CircleCheck /> : <MoodCry />}</>,
      autoClose: 10000,
    });
  };

  // --------------------------------------- dialog / model

  const [dialogOpened, setDialogOpened] = useState(false);

  // --------------------------------------- global variable
  const [value_CafesList, setValue_CafesList] = useState([]);
  const [seleted_rowData, setSeleted_rowData] = useState([]);

  useEffect(() => {
    get_DB_Users_all();
  }, []);

  return (
    <div>
      <COM_NavBar_Global />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <MaterialTable
        style={{
          MuiTablePaginationRoot: "gold",
          color: "white",
          // background: "rgba(0, 0, 100, .0)",
          backgroundImage: "linear-gradient(to bottom right, #ffd900, #ffa90a)",
          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={
          <div className="flexRow">
            <div className="text_TableTitle margin_1rem_leftright">Users</div>
            <Button
              className="margin_1rem_leftright"
              size="xl"
              // variant="subtle"
              color="grape"
              onClick={() => {
                get_DB_Users_all();
              }}
            >
              <Refresh />
              Reload Data
            </Button>
          </div>
        }
        columns={columns}
        data={DB_Users_all}
        options={{
          headerStyle: {
            background: "rgba(255, 255, 255, .2)",
            border: "none",
          },
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor: "#1a0000",
            color: "#ffffff",
            fontSize: 12,
          }),
          filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 50,
          pageSizeOptions: [100, 200],
          paginationType: "stepped",
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
          // exportMenu: [
          //   {
          //     label: "Export PDF",
          //     exportFunc: (cols, datas) =>
          //       ExportPdf(cols, datas, "myPdfFileName"),
          //   },
          //   {
          //     label: "Export CSV",
          //     exportFunc: (cols, datas) =>
          //       ExportCsv(cols, datas, "myCsvFileName"),
          //   },
          // ],
        }}
        // onRowClick={(evt, selectedRow) => {
        // alert(selectedRow.id);
        // }}
        // actions={[
        //   {
        //     icon: "save",
        //     tooltip: "Save User",
        //     onClick: (event, rowData) => {
        //       // handleClickOpen_1_2();
        //       // getDB_patroninfo_byid(rowData.id);
        //       // alert(rowData.id);
        //     },
        //   },
        // ]}
        components={{
          Action: (props) => (
            <>
              {/* <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "gold" }}>DONE</div>
              </Button>
              <Button
              classes={{ root: classes.button }}
              onClick={(event) => props.action.onClick(event, props.data)}
              >
                <div style={{ color: "#2bc3ff" }}>EDIT</div>
              </Button> */}
            </>
          ),
        }}
      />
      <Modal
        opened={dialogOpened}
        onClose={() => setDialogOpened(false)}
        title={<h1>{`Setting Users`}</h1>}
        centered
        size="full"
        transition="slide-down"
        transitionDuration={600}
        transitionTimingFunction="ease"
        overflow="inside"
      >
        <h1>Cafes List</h1>
        <CheckboxGroup
          // defaultValue={["react"]}
          orientation="horizontal"
          // label="Select your favorite framework/library"
          // description="This is anonymous"
          // required
          value={value_CafesList}
          // defaultValue={value_CafesList}
          onChange={(e) => {
            setValue_CafesList(e);
            // console.log(e);
          }}
        >
          {cafesList.map((x) => (
            <Checkbox
              value={x}
              label={<div>{x}</div>}
              // label={x}
              // checked={checked}
              // onChange={(event) =>
              //   // setChecked(event.currentTarget.checked)
              //   alert("")
              // }
            />
          ))}
        </CheckboxGroup>
        <br />
        <br />
        <br />
        <h1>Set Auth</h1>
        <div className="container_center gap_dot2rem">
          <Button
            fullWidth
            size="xl"
            color={seleted_rowData?.u_role == "admin" ? "yellow" : "dark"}
            onClick={() => {
              let y = "";
              value_CafesList?.sort()?.map((x) => (y += x + ","));
              // console.log("y", value_CafesList);
              if (window.confirm("Set to Admin") == true) {
                set_DB_User__byuid(
                  "admin",
                  y.slice(0, -1),
                  contextValue?.firebase_UserInfo?.displayName,
                  contextValue?.firebase_UserInfo?.photoURL,
                  seleted_rowData?.u_uid
                );
              }
            }}
          >
            Admin
          </Button>
          <Button
            fullWidth
            size="xl"
            color={seleted_rowData?.u_role == "staff" ? "yellow" : "dark"}
            onClick={() => {
              let y = "";
              value_CafesList.map((x) => (y += x + ","));
              if (window.confirm("Set to Staff") == true) {
                set_DB_User__byuid(
                  "staff",
                  y.slice(0, -1),
                  contextValue?.firebase_UserInfo?.displayName,
                  contextValue?.firebase_UserInfo?.photoURL,
                  seleted_rowData?.u_uid
                );
              }
            }}
          >
            Staff
          </Button>
          <Button
            fullWidth
            size="xl"
            color={
              seleted_rowData?.u_role != "admin" &&
              seleted_rowData?.u_role != "staff"
                ? "red"
                : "dark"
            }
            onClick={() => {
              let y = "";
              value_CafesList?.map((x) => (y += x + ","));
              if (window.confirm("Set to NOT ALLOW") == true) {
                set_DB_User__byuid(
                  "not allow",
                  y.slice(0, -1),
                  contextValue?.firebase_UserInfo?.displayName,
                  contextValue?.firebase_UserInfo?.photoURL,
                  seleted_rowData?.u_uid
                );
              }
            }}
          >
            NOT ALLOW
          </Button>
          <br />
          <br />
        </div>
      </Modal>
    </div>
  );
}
